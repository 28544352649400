import React from "react"
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews"

import { linkResolver } from "../gatsby/linkResolver"

import Layout from "../components/Layout"

const PreviewPage = ({ isPreview }) => {
  if (isPreview === false) return "Not a preview!"

  return (
    <Layout>
      <p>Loading</p>
    </Layout>
  )
}

export default withPrismicPreviewResolver(PreviewPage)
